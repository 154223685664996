import { Flex, Modal, ModalContent, useDisclosure } from "@chakra-ui/react";

import { OpenModalLink } from "../../../atoms/Link/OpenModalLink";
import { ModalGiftListTextSubtitleProps } from "../../../molecules/Modal/ModalGiftListTextSubtitle";
import { ModalGiftListBody } from "../../../organisms/Modal/ModalGiftListBody";
interface ModalGiftListProps {
  size?: number;
  color: string;
  buttonBg: string;
  buttonTextColor: string;
  giftList: ModalGiftListTextSubtitleProps[];
  subtitleColor?: string;
  subtitleSize?: number;
}

export function ModalGiftList({
  buttonBg,
  buttonTextColor,
  size,
  color,
  giftList,
  subtitleColor,
  subtitleSize,
}: ModalGiftListProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Flex justify="center" my={2}>
      <OpenModalLink
        color={color}
        onClick={onOpen}
        text="Ver prêmios"
        size={size}
      />
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="xs">
        <ModalContent>
          <ModalGiftListBody
            onClose={onClose}
            buttonBg={buttonBg}
            buttonTextColor={buttonTextColor}
            subtitleSize={subtitleSize}
            subtitleColor={subtitleColor}
            giftColor="#FA9F42"
            giftList={giftList}
          />
        </ModalContent>
      </Modal>
    </Flex>
  );
}
