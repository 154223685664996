import { useContext } from "react";

import { WinningRegistrationContext } from "../contexts/winningRegistrationContext";

export function useWinningRegistration() {
  const context = useContext(WinningRegistrationContext);

  if (!context) {
    throw new Error(
      "useWinningRegistration must be used within a WinningRegistrationProvider"
    );
  }

  return context;
}
