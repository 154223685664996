import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { api } from "../services/api";
import { useQuery } from "./query";

interface Search {
  id: string;
  name: string;
  has_cnpj?: boolean;
  cover_url: string;
  company_id: string;
  client_message: string;
  thanks_message?: string;
  slug: string;
  has_page: boolean;
  form_message: string;
  discursive_question: string;
  coupon_message: string;
  nps: string;
  has_promoter_replica?: boolean;
  store_client_with_qrcode?: boolean;
  has_neutral_replica?: boolean;
  has_detractor_replica?: boolean;
  is_enps?: boolean;
  has_transparency: boolean;
  is_totem: boolean;
  question_page?: string;
  reverse_order: boolean;
  anonymous_search: boolean;
  unique_answer_client: boolean;
  promoter_redirect_link?: string;
  neutral_redirect_link?: string;
  detractor_redirect_link?: string;
  is_partitioned: boolean;
  type_form: string;
  active: boolean;
  has_redirect?: boolean;
  has_linktree?: boolean;
  skip_registration_decision?: boolean;
  redirect_link: string;
  external_link?: string;
  name_external_link?: string;
  min_to_next_answer: number;
  time_reload_screen?: number;
  has_nps_first: boolean;
  no_register?: boolean;
  title?: string;
  roulette_id?: string;
  unifyQrcodesSearches: {
    unify_qrcode_id: string;
    unifyQrcode: {
      unifyQrcodesFranchisesGroupUnifyQrcodes: {
        id: string;
        unify_qrcode_franchise_group_id: string;
      }[];
    };
  }[];
}

interface Feature {
  facebook_pixel?: string;
  google_tag_manager?: string;
  google_analytics?: string;
  has_goomer: boolean;
}

interface Company {
  id: string;
  name: string;
  genre: string;
  slug: string;
  logo_url: string;
  logo: string;
  color: string;
  privacy_policy_link?: string;
  collect_cpf: boolean;
  secondary_color: string;
  is_totem: boolean;
  has_fidelity_confirmation: boolean;
  default_country: string;
}
interface FormClientProviderProps {
  children: ReactNode;
}

interface WhiteLabel {
  name: string;
  form_domain: string;
  logo_horizontal_url: string;
  logo_vertically_url: string;
  primary_color: string;
  favicon_url: string;
  secondary_color: string;
}

interface FormClientContextProps {
  search: Search;
  company: Company;
  feature: Feature;
  replaceQuestion: string;
  page?: string;
  questionQuery?: string;
  isTest?: boolean;
  client?: string;
  consumption?: string;
  external_id?: string;
  redirect_link?: string;
  dispatch?: string;
  checkDispatch: boolean;
  whiteLabel?: WhiteLabel;
  updateWhiteLabel: (whiteLabel: WhiteLabel) => void;
  typeAnswer: "promoter" | "neutral" | "detractor" | "";
  setTypeAnswer: (type: "promoter" | "neutral" | "detractor") => void;
}
export const FormClientContext = createContext<FormClientContextProps>(
  {} as FormClientContextProps
);

export function useFormClient(): FormClientContextProps {
  const context = useContext(FormClientContext);

  if (!context) {
    throw new Error("useFormClient must be used");
  }

  return context;
}

export function FormClientProvider({ children }: FormClientProviderProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const query = useQuery();
  const [search, setSearch] = useState<Search>({} as Search);
  const [company, setCompany] = useState<Company>({} as Company);
  const [whiteLabel, setWhiteLabel] = useState<WhiteLabel>();
  const [feature, setFeature] = useState<Feature>({} as Feature);
  const [page, setPage] = useState<string>("");
  const [consumption, setConsumption] = useState<string>("");
  const [externalId, setExternalId] = useState<string>("");
  const [redirectLink, setRedirectLink] = useState<string>("");
  const [client, setClient] = useState<string>("");
  const [isTest, setIsTest] = useState<boolean>(false);
  const [typeAnswer, setTypeAnswer] = useState<
    "promoter" | "neutral" | "detractor" | ""
  >("");
  const [checkDispatch, setCheckDispatch] = useState<boolean>(
    query.get("d") ? false : true
  );
  const [dispatch, setDispatch] = useState<string>("");
  const [questionQuery, setQuestionQuery] = useState<string>("");

  const updateGoomer = (has_goomer: boolean) => {
    if (has_goomer) {
      if (query.get("red")) {
        setRedirectLink(query.get("red")!);
      }

      if (query.get("ext_id")) {
        setExternalId(query.get("ext_id")!);
      }
    }
  };
  const replaceQuestion = useMemo(() => {
    if (company) {
      if (company.genre === "F") {
        return `a ${company.name}`;
      } else {
        return `o ${company.name}`;
      }
    }
    return "";
  }, [company]);

  useEffect(() => {
    const [, id, slug, search_slug] = location.pathname.split("/");
    if (id !== "formularios") {
      api
        .get(`/clientForms/search/${id}`)
        .then((response) => {
          setCompany(response.data.company);
          setSearch(response.data.search);
          setFeature(response.data.feature);
          updateGoomer(response.data.feature.has_goomer);
        })
        .catch((err) => console.log(err));
    } else {
      if (slug) {
        api
          .get(`/clientForms/${slug}/${search_slug}`)
          .then((response) => {
            setCompany(response.data.company);
            setSearch(response.data.search);
            setFeature(response.data.feature);
            updateGoomer(response.data.feature.has_goomer);
          })
          .catch((err) => console.log(err));
      } else {
        if (search_slug === "satisfacao") {
          window.location.href =
            "https://falae.experienciab2s.com/formularios/japate-jardim-norte/satisfacao";
        }
      }
    }

    if (query.get("t")) {
      setIsTest(Boolean(query.get("t")));
    }

    if (query.get("c")) {
      setClient(query.get("c")!);
    }
    if (query.get("d")) {
      setDispatch(query.get("d")!);
      api
        .get(`/dispatches/check/${query.get("d")}/${query.get("c")}`)
        .then((response) => {
          if (response.data.time === false) {
            navigate(`/formularios/${company.slug}/${search.slug}/encerrada`);
          }
          if (response.data.answer === false) {
            navigate(`/formularios/${company.slug}/${search.slug}/respondida`);
          }

          setCheckDispatch(true);
        })
        .catch((err) => {
          setCheckDispatch(true);
          console.log(err);
        });
    }

    if (query.get("p")) {
      setPage(query.get("p")!);
    }

    if (query.get("order")) {
      api
        .get(`/clientForms/consumption/valid/${query.get("order")}`)
        .then((response) => {
          if (response.data === false) {
            navigate(`/formularios/${slug}/${search_slug}/respondida`);
          }
          setCheckDispatch(true);
        })
        .catch((err) => {
          setCheckDispatch(true);
          console.log(err);
        });
      setConsumption(query.get("order")!);
    }
    let result = location.search;

    result = result
      .replace(`c=${query.get("c")}`, "")
      .replace(`t=${query.get("t")}`, "")
      .replace(`p=${query.get("p")}`, "")
      .replace(`d=${query.get("d")}`, "")
      .replace(`red=${query.get("red")}`, "")
      .replace(`ext_id=${query.get("ext_id")}`, "")
      .replace(`order=${query.get("order")}`, "")
      .replace("?", "");
    setQuestionQuery(result);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const updateWhiteLabel = useCallback((whiteLabel: WhiteLabel) => {
    setWhiteLabel(whiteLabel);
  }, []);
  return (
    <FormClientContext.Provider
      value={{
        isTest,
        search,
        feature,
        client,
        company,
        page,
        questionQuery,
        replaceQuestion,
        dispatch,
        checkDispatch,
        setTypeAnswer,
        typeAnswer,
        whiteLabel,
        updateWhiteLabel,
        consumption,
        external_id: externalId,
        redirect_link: redirectLink,
      }}
    >
      {children}
    </FormClientContext.Provider>
  );
}
