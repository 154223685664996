import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import { api } from "../../../services/api";
import {
  WinningRegistrationContext,
  WinningRegistrationContextProps,
} from "../contexts/winningRegistrationContext";

interface WinningRegistrationProviderProps {
  children: ReactNode;
}

export function WinningRegistrationProvider({
  children,
}: WinningRegistrationProviderProps) {
  const location = useLocation();
  const [company, setCompany] = useState<
    WinningRegistrationContextProps["company"]
  >({} as WinningRegistrationContextProps["company"]);
  const [whiteLabel, setWhiteLabel] =
    useState<WinningRegistrationContextProps["whiteLabel"]>();
  const [feature, setFeature] = useState<
    WinningRegistrationContextProps["feature"]
  >({} as WinningRegistrationContextProps["feature"]);
  const [winningRegistration, setWinningRegistration] = useState<
    WinningRegistrationContextProps["winningRegistration"]
  >({} as WinningRegistrationContextProps["winningRegistration"]);

  const fetchData = async (firstPath: string, secondPath?: string) => {
    try {
      const endpoint = secondPath
        ? `/form/winningRegistration/slug/${firstPath}/${secondPath}`
        : `/form/winningRegistration/${firstPath}`;

      const response = await api.get(endpoint);
      setCompany(response.data.company);
      setFeature(response.data.feature);
      setWinningRegistration(response.data.winningRegistration);
    } catch (error) {
      console.error("Erro ao buscar os dados da Winning Registration:", error);
    }
  };

  useEffect(() => {
    const [, , firstPath, secondPath] = location.pathname.split("/");
    if (firstPath) {
      fetchData(firstPath, secondPath);
    }
  }, []);

  const updateWhiteLabel = useCallback(
    (whiteLabel: WinningRegistrationContextProps["whiteLabel"]) => {
      setWhiteLabel(whiteLabel);
    },
    []
  );

  const providerValue = useMemo(
    () => ({
      company,
      whiteLabel,
      feature,
      winningRegistration,
      updateWhiteLabel,
    }),
    [company, whiteLabel, feature, winningRegistration, updateWhiteLabel]
  );

  return (
    <WinningRegistrationContext.Provider value={providerValue}>
      {children}
    </WinningRegistrationContext.Provider>
  );
}
