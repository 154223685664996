import { Box, Flex, Text } from "@chakra-ui/react";
import download from "downloadjs";
import * as htmlToImage from "html-to-image";
import { useCallback, useEffect, useState } from "react";
import Confetti from "react-confetti";
import { useParams } from "react-router-dom";

import { LoadingSolidButton } from "../../../components/atoms/Button/LoadingSolidButton";
import { OutlineListButton } from "../../../components/atoms/Button/OutlineListButton";
import { HTMLText } from "../../../components/atoms/Div/HTMLText";
import { ExternalLink } from "../../../components/atoms/Link/ExternalLink";
import { TitleDefault } from "../../../components/atoms/Text/TitleDefault";
import { TimerToast } from "../../../components/molecules/TimerToast";
import { Coupon } from "../../../components/templates/Coupon";
import { useFormClient } from "../../../hooks/formClient";
import { api } from "../../../services/api";
import { formatDateBr } from "../../../utils/formatDate";
interface CouponProps {
  id: string;
  code: string;
  expiration_date: Date;
  client_id: string;
  client: {
    name: string;
  };
  bonus: {
    name: string;
    bonuses_message?: string;
    total_visits: number;
  };
}

interface Search {
  promoter_redirect_link?: string;
  neutral_redirect_link?: string;
  detractor_redirect_link?: string;
  has_redirect?: boolean;
  redirect_link?: string;
}

export function IndexEndControl() {
  const { coupon_id } = useParams();
  const [coupon, setCoupon] = useState<CouponProps>({} as CouponProps);
  const [loading, setLoading] = useState<boolean>(false);
  const [secondsAmount, setSecondsAmount] = useState(15);
  const [showTimer, setShowTimer] = useState(false);
  const [logo, setLogo] = useState("");
  const { innerWidth: width, innerHeight: height } = window;
  const {
    company,
    search,
    replaceQuestion,
    typeAnswer,
    whiteLabel,
    redirect_link,
  } = useFormClient();

  const handleDownloadCoupon = useCallback(async () => {
    const node = document.getElementById("coupon");
    if (node) {
      await htmlToImage.toPng(node);
      htmlToImage.toPng(node).then(function (dataUrl) {
        download(dataUrl, `cupom.png`);
        setLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    if (typeof coupon_id !== typeof undefined) {
      api.get(`/coupons/${coupon_id}`).then((response) => {
        setCoupon(response.data);
      });
      api.get(`/companies/logo/${company.logo}`).then((response) => {
        setLogo(response.data);
      });
    }
  }, [company.logo, coupon_id]);

  const getRedirectLink = (
    typeAnswer: string,
    search: Search
  ): string | undefined => {
    if (redirect_link) {
      return redirect_link;
    } else {
      switch (typeAnswer) {
        case "promoter": {
          if (search.promoter_redirect_link) {
            return search.promoter_redirect_link;
          }
          return search.has_redirect ? search.redirect_link : undefined;
        }
        case "neutral": {
          if (search.neutral_redirect_link) {
            return search.neutral_redirect_link;
          }
          return search.has_redirect ? search.redirect_link : undefined;
        }
        case "detractor": {
          if (search.detractor_redirect_link) {
            return search.detractor_redirect_link;
          }
          return search.has_redirect ? search.redirect_link : undefined;
        }
        default:
          return search.has_redirect ? search.redirect_link : undefined;
      }
    }
  };

  useEffect(() => {
    const redirectLink = getRedirectLink(typeAnswer, search);

    if (!redirectLink) return;

    setShowTimer(true);

    const interval = setTimeout(
      () => {
        if (secondsAmount > 0 && coupon_id !== undefined) {
          setSecondsAmount((state) => state - 1);
        } else {
          window.location.assign(redirectLink);
        }
      },
      coupon_id !== undefined ? 1000 : 0
    );

    return () => clearTimeout(interval);
  }, [coupon_id, search, typeAnswer, secondsAmount]);
  localStorage.removeItem(`@B2S:link`);

  return (
    <>
      <Flex alignItems="center" direction="column">
        {coupon.id ? (
          <>
            <TimerToast
              color={company.secondary_color}
              show={showTimer}
              message={`Tempo para o redirecionamento: ${secondsAmount}s`}
            />
            <Confetti
              width={width - 20}
              height={height + 100}
              colors={[
                company.color,
                company.secondary_color,
                "#2b88a1",
                "#ff9000",
              ]}
            />
            <Text as="h2" fontSize="18px" mb="6px" fontWeight="regular">
              Parabéns {coupon.client.name},
            </Text>
            <Text mb="5px">
              Sua resposta foi registrada com sucesso ! Você acaba de ganhar{" "}
              {coupon.bonus?.name} no seu próximo encontro com {replaceQuestion}{" "}
            </Text>
            <HTMLText html={search.coupon_message} />

            <Text mb="5px">
              <b>Validade: {formatDateBr(coupon.expiration_date)}</b>
            </Text>
            <Box my={5}>
              <LoadingSolidButton
                loading={loading}
                bg={company.color}
                color="white"
                onClick={() => {
                  setLoading(true);
                  handleDownloadCoupon();
                }}
              >
                Fazer Download
              </LoadingSolidButton>
            </Box>
            <Coupon
              client={coupon.client}
              bonus={coupon.bonus}
              code={coupon.code}
              logo={logo}
              company={company}
              expiration_date={coupon.expiration_date}
              is_falae={whiteLabel === undefined}
            />
          </>
        ) : (
          <>
            <TitleDefault
              title={
                search.title
                  ? search.title
                  : `Pesquisa de Satisfação ${
                      !search.is_enps ? `- ${company.name}` : ""
                    }`
              }
            />
            <Box mt={5}>
              {search.thanks_message ? (
                <HTMLText html={search.thanks_message} />
              ) : (
                <Text mt="50px">Sua resposta foi registrada com sucesso! </Text>
              )}
            </Box>
          </>
        )}
        <Box my={5}>
          {search.name_external_link && search.external_link && (
            <ExternalLink link={search.external_link}>
              <OutlineListButton
                text={search.name_external_link}
                color="#5C5C5C"
              />
            </ExternalLink>
          )}
        </Box>
      </Flex>
    </>
  );
}
