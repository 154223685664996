import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Loader } from "../../../../components/templates/Loader";
import { useWinningRegistration } from "../../hooks/useWinningRegistration";

export function WinningRegistrationLoader() {
  const { company, winningRegistration } = useWinningRegistration();
  const navigate = useNavigate();

  useEffect(() => {
    if (winningRegistration.id) {
      if (winningRegistration.status) {
        navigate(`/premiado/${company.slug}/${winningRegistration.slug}`);
      } else {
        navigate(
          `/premiado/${company.slug}/${winningRegistration.slug}/desligado`
        );
      }
    }
  }, [company.slug, winningRegistration.id, navigate]);

  return (
    <Loader
      isLoading={false}
      whiteLabel={{
        logo_vertically_url: "",
        name: "Falaê",
        primary_color: "#2b88a1",
      }}
    >
      <></>
    </Loader>
  );
}
