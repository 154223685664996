import { Checkbox, CheckboxGroup, Flex, Input, Stack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { SubmitButton } from "../../../../components/atoms/Button/SubmitButton";
import { HTMLText } from "../../../../components/atoms/Div/HTMLText";
import { PrivacityText } from "../../../../components/atoms/Text/PrivacityText";
import { SubtitleDefault } from "../../../../components/atoms/Text/SubtitleDefault";
import { TitleDefault } from "../../../../components/atoms/Text/TitleDefault";
import { StoreClientForm } from "../../../../components/templates/StoreClient";
import { api } from "../../../../services/api";
import { phoneNumberValidation } from "../../../../utils/phoneValidation";
import { useWinningRegistration } from "../../hooks/useWinningRegistration";

const schema = yup.object().shape({
  name: yup
    .string()
    .required("Nome obrigatório")
    .min(3, "O nome deve conter no mínimo 3 caracteres"),
  surname: yup
    .string()
    .required("Sobrenome obrigatório")
    .min(3, "O sobrenome deve conter no mínimo 3 caracteres"),
  email: yup
    .string()
    .required("O E-mail é obrigatório")
    .email("Formato inválido")
    .min(6, "Número de caracteres muito pequeno"),
  phone: yup
    .string()
    .required("O Whatsapp é obrigatório")
    .test(
      "is-valid-phone",
      "Número de telefone inválido",
      phoneNumberValidation
    ),
  born_date: yup
    .string()
    .nullable()
    .matches(
      /(0?[1-9]|[12]\d|30|31)\/(0[1-9]|1[0-2])\/(19[0-9]{2}|20[0-1][0-9])/,
      "Formato inválido "
    ),
  approved: yup.boolean().nullable(),
});

interface IProps {
  is_totem?: boolean;
}

export function CreateClientWinningRegistration({ is_totem }: IProps) {
  const { company, winningRegistration } = useWinningRegistration();
  const navigate = useNavigate();
  const { register, handleSubmit, formState } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  const handleCreateClient: SubmitHandler<FieldValues> = useCallback(
    async (formValue) => {
      await api
        .post(`/form/winningRegistration/${winningRegistration.id}`, {
          name: formValue.name.trim() + " " + formValue.surname.trim(),
          cpf: formValue.cpf,
          email: formValue.email.trim(),
          phone: formValue.phone,
          born_date: formValue.born_date,
          approved: formValue.approved,
          company_id: company.id,
        })
        .then((response) => {
          if (winningRegistration.bonus_id) {
            if (response.data.coupon) {
              navigate(
                `/premiado/${company.slug}/${winningRegistration.slug}/obrigado/${response.data.coupon.id}`
              );
            }
          } else if (winningRegistration.roulette_id) {
            navigate(
              `/premiado/${company.slug}/${winningRegistration.slug}/roleta/${winningRegistration.roulette_id}/${response.data.id}`
            );
          } else {
            navigate(
              `/premiado/${company.slug}/${winningRegistration.slug}/obrigado`
            );
          }
        })
        .catch(() => {
          navigate(
            `/premiado/${company.slug}/${winningRegistration.slug}/obrigado`
          );
        });
    },
    [company.id, company.slug, winningRegistration, navigate]
  );

  return (
    <>
      <TitleDefault title="Cadastro Premiado" />
      <SubtitleDefault subtitle={company.name} />
      <HTMLText html={winningRegistration.description} />

      <Stack spacing={2}>
        <Flex
          as="form"
          flexDir="column"
          w="100%"
          id="winning-registration"
          onSubmit={handleSubmit(handleCreateClient)}
        >
          <StoreClientForm
            default_country={company.default_country}
            register={register}
            errors={errors}
            bg={company.color}
            has_cnpj={false}
            has_cpf={company.collect_cpf}
            is_totem={is_totem}
          />
          <CheckboxGroup>
            <Flex alignItems="start" justify="start" flexDir="column">
              <Checkbox {...register("approved")} isRequired={true} variant="">
                <PrivacityText link={company.privacy_policy_link} />
              </Checkbox>
            </Flex>
            <Input required={true} type="hidden" />
          </CheckboxGroup>
        </Flex>
      </Stack>

      <Flex justify="flex-end" alignItems="center">
        <SubmitButton
          bg={company.color}
          color="white"
          text="Cadastrar"
          form="winning-registration"
          isLoading={formState.isSubmitting}
        />
      </Flex>
    </>
  );
}
