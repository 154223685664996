import { Box, Flex, Text, useMediaQuery } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { TitleDefault } from "../../../../components/atoms/Text/TitleDefault";
import { TimerToast } from "../../../../components/molecules/TimerToast";
import { Roulette } from "../../../../components/Roulette";
import { ModalGiftList } from "../../../../components/templates/Modal/ModalGiftList";
import { api } from "../../../../services/api";
import { useWinningRegistration } from "../../hooks/useWinningRegistration";
import { WinningRegistrationLoader } from "../Loader";

interface Bonus {
  name: string;
  id: string;
}

export function WinningRegistrationRoulette() {
  const [isMobile] = useMediaQuery("(max-width: 500px)");
  const { company, winningRegistration } = useWinningRegistration();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [bonusId, setBonusId] = useState("");
  const [couponId, setCouponId] = useState("");
  const [wheelloading] = useState(false);
  const [bonuses, setBonuses] = useState<Bonus[]>([]);
  const { roulette_id, client_id } = useParams();
  const [secondsAmount, setSecondsAmount] = useState(10);
  const [showTimer, setShowTimer] = useState(false);
  const getPrizeIndex = useCallback(() => {
    if (!bonusId || bonuses.length === 0) return -1;
    return bonuses.findIndex((b) => b.id === bonusId);
  }, [bonusId, bonuses]);

  const handleSpinWheel = useCallback(async () => {
    try {
      const response = await api.post(`/form/winningRegistration/roulettes/`, {
        client_id,
        roulette_id,
      });
      setCouponId(response.data.coupon_id);
      setBonusId(response.data.bonus_id);

      const prizeIndex = bonuses.findIndex(
        (b) => b.id === response.data.bonus_id
      );
      setPrizeNumber(prizeIndex !== -1 ? prizeIndex : 0);
      setMustSpin(true);
    } catch (error) {
      console.error("Erro ao determinar o prêmio:", error);
      setLoading(false);
    }
  }, [client_id, roulette_id, getPrizeIndex]);

  const handleStopSpinning = useCallback(() => {
    setMustSpin(false);
    setShowTimer(true);

    const interval = setInterval(() => {
      setSecondsAmount((prev) => {
        if (prev <= 1) {
          clearInterval(interval);
          navigate(
            `/premiado/${company.slug}/${winningRegistration.slug}/obrigado/${couponId}`
          );
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  }, [couponId, navigate]);

  useEffect(() => {
    setLoading(true);
    api
      .get(`/form/winningRegistration/roulettes/${roulette_id}/${client_id}`)
      .then((response) => {
        setBonuses(response.data);
        setLoading(false);
      })
      .catch(() => {
        navigate(
          `/premiado/${company.slug}/${winningRegistration.slug}/obrigado`
        );
        setLoading(false);
      });
  }, [roulette_id, client_id]);

  return (
    <>
      <TitleDefault title={`Cadastro Premiado - ${company.name}`} />
      <TimerToast
        color={company.secondary_color}
        show={showTimer}
        message={`Tempo para o redirecionamento: ${secondsAmount}s`}
      />
      {!loading ? (
        <Box
          my={5}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={4}
        >
          <Text mt="50px">Cadastro realizado com sucesso!</Text>
          <Flex mb={isMobile ? 8 : 0}>
            <ModalGiftList
              size={18}
              subtitleColor="black"
              subtitleSize={16}
              color={company.color}
              buttonTextColor="white"
              buttonBg={company.color}
              giftList={bonuses.map((bonus, index) => {
                return {
                  subtitle: bonus.name.toUpperCase(),
                  color: company.color,
                  type: `${index + 1}º Prêmio`,
                };
              })}
            />
          </Flex>
          <Roulette
            {...{
              handleStopSpinning,
              loading: wheelloading,
              mustSpin,
              prizeNumber,
              handleSpinWheel,
              company,
            }}
            bonuses={bonuses}
          />
        </Box>
      ) : (
        <></>
      )}
    </>
  );
}
