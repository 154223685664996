import { Flex } from "@chakra-ui/react";

import { FidelityProgramColorText } from "../../../atoms/Text/FidelityProgramColorText";
import { SubtitleDefault } from "../../../atoms/Text/SubtitleDefault";
export interface ModalGiftListTextSubtitleProps {
  subtitle: string;
  color: string;
  type: string;
  subtitleColor?: string;
  subtitleSize?: number;
}

export function ModalGiftListTextSubtitle({
  subtitle,
  color,
  type,
  subtitleColor,
  subtitleSize,
}: ModalGiftListTextSubtitleProps) {
  return (
    <Flex
      justify="space-around"
      align="center"
      flexDir="column"
      minH="50px"
      mb={2}
    >
      <FidelityProgramColorText text={type} color={color} />
      <SubtitleDefault
        subtitleColor={subtitleColor}
        subtitleSize={subtitleSize}
        subtitle={subtitle}
      />
    </Flex>
  );
}
