import { Box, Flex, Text } from "@chakra-ui/react";

import { TitleDefault } from "../../../../components/atoms/Text/TitleDefault";
import { useWinningRegistration } from "../../hooks/useWinningRegistration";

export function DisabledControlWinningRegistration() {
  const { winningRegistration } = useWinningRegistration();
  return (
    <Flex alignItems="center" direction="column">
      <TitleDefault title={winningRegistration.name} />

      <Box mt={5}>
        <Text mt="50px">O formulário de cadastro está desativado</Text>
      </Box>
    </Flex>
  );
}
