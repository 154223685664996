import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { api } from "../../../../services/api";
import { DinamicSolidButton } from "../../../atoms/Button/DinamicSolidButton";
import { ClientConfirmatioTitle } from "../../../atoms/Text/ClientConfirmationTitle";
import { DinamicClickText } from "../../../atoms/Text/DinamicClickText";

interface ClientConfirmationModalSearchProps {
  name: string;
  email: string;
  company: {
    id: string;
    slug: string;
    color: string;
  };
  search: {
    id: string;
    slug: string;
    company_id: string;
    type_form: string;
    has_page: boolean;
  };
  answer_id?: string;
  setLoading: (status: boolean) => void;
}
export function ClientConfirmationModalSearch({
  name,
  company,
  email,
  answer_id,
  search,
  setLoading,
}: ClientConfirmationModalSearchProps) {
  const { onClose } = useDisclosure();
  const navigate = useNavigate();
  const [onOpen, setOnOpen] = useState(true);
  const handleUpdateClient = useCallback(async () => {
    try {
      setLoading(false);
      if (answer_id) {
        await api
          .post("/clientForms/update", {
            company_id: search.company_id,
            email,
            answer_id: answer_id,
            search_id: search.id,
          })
          .then((response) => {
            localStorage.setItem(
              `@B2S-${company.slug}:user`,
              JSON.stringify({
                name: response.data.name,
                email: response.data.email,
                id: response.data.id,
              })
            );
            if (response.data.roulette_id) {
              navigate(
                `/formularios/${company.slug}/${search.slug}/roleta/${response.data.roulette_id}/${response.data.id}/${answer_id}`
              );
              return;
            }
            if (response.data.coupons) {
              navigate(
                `/formularios/${company.slug}/${search.slug}/obrigado/${response.data.coupons[0].id}`
              );
            } else {
              navigate(`/formularios/${company.slug}/${search.slug}/obrigado`);
            }
          });
      } else {
        const response = await api.post("/clientForms/update", {
          company_id: search.company_id,
          email,
        });
        if (search.has_page) {
          navigate(
            `/formularios/${company.slug}/${search.slug}/pagina/inicial/${response.data.id}`
          );
        } else {
          navigate(
            `/formularios/${company.slug}/${search.slug}/${response.data.id}`
          );
        }
      }
      setLoading(true);
    } catch (err: any) {
      if (err.message === "Request failed with status code 404") {
        navigate(
          `/formularios/${company.slug}/${search.slug}/cliente/cadastro${
            answer_id ? "/" + answer_id : ""
          }?type=true`
        );
      }
    }
  }, [
    setLoading,
    answer_id,
    search.id,
    search.company_id,
    search.slug,
    search.has_page,
    email,
    company.slug,
    navigate,
  ]);
  return (
    <Modal isOpen={onOpen} onClose={onClose} isCentered={true} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <ClientConfirmatioTitle name={name} />

          <Flex
            align="center"
            justifyContent="center"
            margin="auto"
            my={5}
            w="100%"
          >
            <DinamicClickText
              text="Não, alterar conta"
              onClick={() => {
                setOnOpen(false);
                setLoading(true);
                localStorage.removeItem(`@B2S-${company.slug}:user`);
              }}
            />
            <Box mx={4}>
              <DinamicSolidButton
                bg={company.color}
                color="white"
                onClick={async () => {
                  setOnOpen(false);
                  await handleUpdateClient();
                }}
              >
                Sim
              </DinamicSolidButton>
            </Box>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
