import { createContext } from "react";

interface Company {
  id: string;
  name: string;
  genre: string;
  slug: string;
  logo_url: string;
  logo: string;
  color: string;
  privacy_policy_link?: string;
  secondary_color: string;
  has_create_client_independent: boolean;
  default_country: string;
  collect_cpf: boolean;
}

interface Feature {
  facebook_pixel?: string;
  google_tag_manager?: string;
  google_analytics: string;
}

interface WinningRegistration {
  id: string;
  slug: string;
  status: boolean;
  name: string;
  description: string;
  company_id: string;
  roulette_id: string;
  bonus_id: string;
}

interface WhiteLabel {
  name: string;
  form_domain: string;
  logo_horizontal_url: string;
  logo_vertically_url: string;
  primary_color: string;
  secondary_color: string;
  favicon_url: string;
}

export interface WinningRegistrationContextProps {
  company: Company;
  whiteLabel?: WhiteLabel;
  feature: Feature;
  updateWhiteLabel: (whiteLabel: WhiteLabel) => void;
  winningRegistration: WinningRegistration;
}

export const WinningRegistrationContext =
  createContext<WinningRegistrationContextProps>(
    {} as WinningRegistrationContextProps
  );
