import {
  Box,
  Divider,
  Flex,
  ModalBody as ChakraModalBody,
} from "@chakra-ui/react";

import { CloseModalButton } from "../../../atoms/Button/CloseModalButton";
import {
  ModalGiftListTextSubtitle,
  ModalGiftListTextSubtitleProps,
} from "../../../molecules/Modal/ModalGiftListTextSubtitle";
import { ModalGiftListTitle } from "../../../molecules/Modal/ModalGiftListTitleProps";

interface ModalGiftListBodyProps {
  giftColor: string;
  buttonBg: string;
  buttonTextColor: string;
  onClose: () => void;
  giftList: ModalGiftListTextSubtitleProps[];
  subtitleColor?: string;
  subtitleSize?: number;
}

export function ModalGiftListBody({
  buttonBg,
  buttonTextColor,
  onClose,
  giftList,
  giftColor,
  subtitleColor,
  subtitleSize,
}: ModalGiftListBodyProps) {
  return (
    <ChakraModalBody
      as={Flex}
      textAlign="center"
      justifyContent="space-around"
      alignItems="center"
      flexDir="column"
    >
      <ModalGiftListTitle color={giftColor} title="Prêmios" />
      {giftList.map((gift) => (
        <>
          <ModalGiftListTextSubtitle
            subtitleColor={subtitleColor}
            subtitleSize={subtitleSize}
            color={gift.color}
            subtitle={gift.subtitle}
            type={gift.type}
          />
          <Divider />
        </>
      ))}
      <Box mb="10px" />
      <CloseModalButton onClose={onClose} bg={buttonBg} color={buttonTextColor}>
        Fechar
      </CloseModalButton>
    </ChakraModalBody>
  );
}
