import { Link } from "@chakra-ui/react";

interface OpenModalLinkProps {
  onClick: () => void;
  text: string;
  color: string;
  size?: number;
}
export function OpenModalLink({
  onClick,
  text,
  color,
  size = 14,
}: OpenModalLinkProps) {
  return (
    <Link
      fontSize={size}
      fontWeight="normal"
      lineHeight="18px"
      color={color}
      textAlign="center"
      onClick={onClick}
    >
      {text}
    </Link>
  );
}
