import React from "react";
import { Route, Routes as ReactRoutes } from "react-router-dom";

import { DisabledControlWinningRegistration } from "../pages/Disabled";
import { WinningRegistrationEnd } from "../pages/End";
import { WinningRegistrationLoader } from "../pages/Loader";
import { CreateClientWinningRegistration } from "../pages/Register";
import { WinningRegistrationRoulette } from "../pages/Roulette";
import { WinningRegistrationProvider } from "../providers";
import { WinningRegistrationTemplate } from "../templates";

const WinningRegistrationRoutes: React.FC = () => {
  return (
    <WinningRegistrationProvider>
      <WinningRegistrationTemplate>
        <ReactRoutes>
          <Route
            path="/:slug/:winning_slug/roleta/:roulette_id/:client_id"
            element={<WinningRegistrationRoulette />}
          />
          <Route
            path="/:slug/:winning_slug/obrigado/:coupon_id?"
            element={<WinningRegistrationEnd />}
          />
          <Route
            path="/:slug/:winning_slug/desligado"
            element={<DisabledControlWinningRegistration />}
          />
          <Route
            path="/:slug/:winning_slug"
            element={<CreateClientWinningRegistration />}
          />
          <Route path="/:id" element={<WinningRegistrationLoader />} />
        </ReactRoutes>
      </WinningRegistrationTemplate>
    </WinningRegistrationProvider>
  );
};

export { WinningRegistrationRoutes };
