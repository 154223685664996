import { Button, Flex, useMediaQuery } from "@chakra-ui/react";
import { useCallback } from "react";
import { Wheel } from "react-custom-roulette";

interface RoletteProps {
  bonuses: {
    name: string;
    id: string;
  }[];
  prizeNumber: number;
  mustSpin: boolean;
  loading: boolean;
  handleStopSpinning: () => void;
  handleSpinWheel: () => void;
  company: {
    color: string;
    secondary_color: string;
  };
}

export function Roulette({
  bonuses,
  prizeNumber,
  mustSpin,
  loading,
  handleStopSpinning,
  handleSpinWheel,
  company,
}: RoletteProps) {
  const [isMobile] = useMediaQuery("(max-width: 500px)");
  const handleSize = useCallback((length: number) => {
    if (length <= 15) {
      return 21;
    } else if (length > 16 && length < 25) {
      return 15;
    } else if (length >= 25) {
      return 12;
    }
  }, []);

  return (
    bonuses.length > 0 && (
      <Flex
        flexDir={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        position={"relative"}
        w-full
        transform={isMobile ? "scale(1.25)" : ""}
      >
        <Wheel
          mustStartSpinning={mustSpin}
          prizeNumber={prizeNumber}
          data={bonuses.map((bonus) => {
            const capitalize = (str: string) =>
              str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
            const size = handleSize(bonus.name.length);
            return {
              option: capitalize(bonus.name),
              style: { fontSize: size },
            };
          })}
          backgroundColors={[company.color, company.secondary_color]}
          outerBorderWidth={0}
          radiusLineWidth={1}
          textColors={["#ffffff"]}
          onStopSpinning={handleStopSpinning}
          textDistance={57}
        />
        <Button
          position={"absolute"}
          zIndex={1000}
          rounded={"full"}
          width={isMobile ? 10 : 14}
          height={isMobile ? 10 : 14}
          fontSize={mustSpin ? 11 : 12}
          onClick={handleSpinWheel}
          shadow={"md"}
          isLoading={loading}
          disabled={mustSpin ?? loading}
        >
          {mustSpin ? "Girando" : "Girar"}
        </Button>
      </Flex>
    )
  );
}
