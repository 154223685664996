import { Text } from "@chakra-ui/react";
interface SubtitleDefaultProps {
  subtitle: string;
  subtitleColor?: string;
  subtitleSize?: number;
}

export function SubtitleDefault({
  subtitle,
  subtitleColor = "gray.400",
  subtitleSize = 14,
}: SubtitleDefaultProps) {
  return (
    <Text
      color={subtitleColor}
      fontSize={subtitleSize}
      lineHeight="18px"
      textAlign="center"
    >
      {subtitle}
    </Text>
  );
}
