import { Box, Flex, Text } from "@chakra-ui/react";
import download from "downloadjs";
import * as htmlToImage from "html-to-image";
import { useCallback, useEffect, useState } from "react";
import Confetti from "react-confetti";
import { useParams } from "react-router-dom";

import { LoadingSolidButton } from "../../../../components/atoms/Button/LoadingSolidButton";
import { TitleDefault } from "../../../../components/atoms/Text/TitleDefault";
import { Coupon } from "../../../../components/templates/Coupon";
import { api } from "../../../../services/api";
import { formatDateBr } from "../../../../utils/formatDate";
import { useWinningRegistration } from "../../hooks/useWinningRegistration";
interface CouponProps {
  id: string;
  code: string;
  expiration_date: Date;
  client_id: string;
  client: {
    name: string;
  };
  bonus: {
    name: string;
    bonuses_message?: string;
    total_visits: number;
  };
}
export function WinningRegistrationEnd() {
  const { company } = useWinningRegistration();
  const { coupon_id } = useParams();
  const [coupon, setCoupon] = useState<CouponProps>({} as CouponProps);
  const [loading, setLoading] = useState<boolean>(false);
  const [logo, setLogo] = useState("");
  const { innerWidth: width, innerHeight: height } = window;

  const handleDownloadCoupon = useCallback(async () => {
    const node = document.getElementById("coupon");
    if (node) {
      await htmlToImage.toPng(node);
      htmlToImage.toPng(node).then(function (dataUrl) {
        download(dataUrl, `cupom.png`);
        setLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    if (typeof coupon_id !== typeof undefined) {
      api.get(`/coupons/${coupon_id}`).then((response) => {
        setCoupon(response.data);
      });
      api.get(`/companies/logo/${company.logo}`).then((response) => {
        setLogo(response.data);
      });
    }
  }, [company.logo, coupon_id]);
  return (
    <Flex alignItems="center" direction="column">
      {coupon.id ? (
        <>
          <Confetti
            width={width}
            height={height}
            colors={[
              company.color,
              company.secondary_color,
              "#2b88a1",
              "#ff9000",
            ]}
          />
          <Text as="h2" fontSize="18px" mb="6px" fontWeight="regular">
            Parabéns {coupon.client.name},
          </Text>
          <Text mb="5px">
            Seu cadastro foi registrado com sucesso ! Você acaba de ganhar{" "}
            {coupon.bonus?.name} no seu próximo encontro com{" "}
            {company.genre === "F" ? "o" : "a"} {company.name}
          </Text>

          <Text mb="5px">
            <b>Validade: {formatDateBr(coupon.expiration_date)}</b>
          </Text>
          <Box my={5}>
            <LoadingSolidButton
              loading={loading}
              bg={company.color}
              color="white"
              onClick={() => {
                setLoading(true);
                handleDownloadCoupon();
              }}
            >
              Fazer Download
            </LoadingSolidButton>
          </Box>
          <Coupon
            client={coupon.client}
            bonus={coupon.bonus}
            code={coupon.code}
            logo={logo}
            company={company}
            expiration_date={coupon.expiration_date}
            is_falae={false}
          />
        </>
      ) : (
        <>
          <TitleDefault title={`Cadastro Premiado - ${company.name}`} />
          <Box mt={5}>
            <Text mt="50px">Cadastro realizado com sucesso!</Text>
          </Box>
        </>
      )}
    </Flex>
  );
}
