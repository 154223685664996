import { Avatar, Box, Text } from "@chakra-ui/react";

import { formatDateBr } from "../../../utils/formatDate";

interface Company {
  color: string;
}

interface Bonuses {
  name: string;
  bonuses_message?: string;
  total_visits: number;
}
interface CouponProps {
  code: string;
  expiration_date: Date;
  logo: string;
  client: {
    name: string;
  };
  bonus: Bonuses;
  company: Company;
  is_falae: boolean;
}

export function Coupon({
  bonus,
  client,
  code,
  expiration_date,
  company,
  logo,
  is_falae,
}: CouponProps) {
  return (
    <Box
      id="coupon"
      m="0"
      p="0"
      fontFamily="sans-serif"
      fontSize="14px"
      bg="white"
      h="430px"
      w="320px"
      border="1px solid black"
      zIndex={1}
    >
      <Box
        borderBottom={`5px solid ${company.color}`}
        bg="black"
        display="flex"
        alignItems="start"
        justifyContent="center"
        h="60px"
      >
        <Text
          paddingTop="8px"
          as="p"
          zIndex="10"
          color="white"
          textAlign="center"
          alignItems="center"
          fontSize="18px"
        >
          <b>ID: </b> {code}
        </Text>
      </Box>
      <Box
        display="flex"
        zIndex="10"
        textAlign="center"
        justifyContent="center"
        alignItems="center"
        minH="100px"
      >
        <Avatar h="100px" w="100px" borderRadius="50%" mt="-30px" src={logo} />
      </Box>
      <Box
        display="flex"
        flexDir="column"
        textAlign="center"
        minH="210px"
        alignItems="center"
        justifyContent="space-around"
        maxW="300px"
        margin="auto"
        textColor="black"
      >
        <Text>
          <b>PARABÉNS, </b>
        </Text>
        <Text>{client.name}</Text>
        <Text>
          <b>VOCÊ GANHOU</b>
        </Text>
        <Text>{bonus.name}</Text>
        {bonus.bonuses_message ? (
          <Box textAlign="center">
            <div
              dangerouslySetInnerHTML={{
                __html: bonus.bonuses_message,
              }}
            />
          </Box>
        ) : (
          <Text fontSize="12px" textAlign="center">
            PARA O NOSSO PRÓXIMO ENCONTRO
          </Text>
        )}
        <Text>
          <b>Validade: </b> {formatDateBr(expiration_date)}
        </Text>
      </Box>
      <Box
        w="100%"
        borderTop={`2px solid ${company.color}`}
        alignContent="center"
        display="flex"
        justifyContent="center"
        alignItems="center"
        bg="black"
        h="60px"
      ></Box>
    </Box>
  );
}
